import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment';

import GButton from './GButton';


import { AppStoreContext } from '../common/AppStore';

import ConversionsHelpTable from './ConversionsHelpTable';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    // height: 291,
    maxHeight: 350,
    overflow: 'auto',
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  table: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    // display: 'contents',
  },
  dateCell: {
    fontSize: 12,
  },
  valueCell: {
    fontSize: 12,
  },
  iconContainer: {
    display: 'contents',
  },
  title: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  alert: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  skel: {
    padding: theme.spacing(3),
  },
  help: {
    float: 'right',
    color: 'silver',
    cursor: 'pointer',
  },
}));

function ActivityList({ handleClickOpenConfirm }) {
  const classes = useStyles();
  const {
    activityList, networkLoading, activityTypes,
  } = useContext(AppStoreContext);

  return (
    activityList.length ? (
      <TableContainer className={classes.list}>
        <Table stickyHeader className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Activity</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Value</TableCell>
              <TableCell align="right">Points</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {activityList.map(({
              unit, value, datetime, activityId, activityTypeId,
            }, i) => {
              const { 'convert-rate': convertion, activityTitle } = activityTypes.filter(
                (ii) => ii.activityTypeId === activityTypeId,
              )[0] || {};

              return (
                <TableRow key={`activity-${activityId}`}>
                  <TableCell component="th" scope="row">
                    {activityTitle}
                  </TableCell>
                  <TableCell align="right" className={classes.dateCell}>{moment(datetime).format('YYYY-MM-DD')}</TableCell>
                  <TableCell align="right" className={classes.valueCell}>{`${value} ${unit}`}</TableCell>
                  <TableCell align="right" className={classes.valueCell}>{Math.round(value * convertion).toString()}</TableCell>
                  <TableCell align="right">
                    {networkLoading['delete-activity'] ? (
                      <ClearIcon style={{ color: 'silver' }} />
                    ) : (
                      <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleClickOpenConfirm(i);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Alert className={classes.alert} severity="info">No activity yet</Alert>
    )
  );
}

export default function LastActivities() {
  const classes = useStyles();
  const {
    networkLoading, deleteActivity, activityList, activityTypes,
  } = useContext(AppStoreContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [activityIndex, setActivityIndex] = React.useState(-1);

  const handleClickOpenConfirm = (i) => {
    setOpenConfirm(true);
    setActivityIndex(i);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setActivityIndex(-1);
  };

  const handleDeleteConfirm = () => {
    setOpenConfirm(false);
    if (activityIndex >= 0) {
      setActivityIndex(-1);
      deleteActivity(activityIndex);
    } else {
      // eslint-disable-next-line no-alert
      alert('Please select activity first!');
    }
  };

  function deleteMessage() {
    const { activityTypeId, unit, value } = activityList[activityIndex];
    const { activityTitle } = activityTypes.filter(
      (ii) => ii.activityTypeId === activityTypeId,
    )[0] || {};
    return `Are you sure you want to delete ${value} ${unit} of "${activityTitle}" ?`;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        My recent activities ⏱
        <HelpOutlineIcon className={classes.help} onClick={handleClickOpen} />
      </Typography>
      {!networkLoading.boot && !networkLoading['get-activity-list'] ? (
        <ActivityList handleClickOpenConfirm={handleClickOpenConfirm} />
      ) : (
        <div className={classes.skel}>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </div>
      )}
      <Dialog onClose={handleClose} aria-labelledby="activity-rate-dialog" open={open} maxWidth="sm" fullWidth>
        <DialogContent>
          <ConversionsHelpTable />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleCloseConfirm} open={openConfirm} maxWidth="sm" fullWidth>
        <DialogContent>
          <DialogContentText>
            {activityIndex >= 0 ? deleteMessage() : 'Are you sure you want to delete? '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} autoFocus>
            Cancel
          </Button>
          <GButton onClick={handleDeleteConfirm} color="red">
            Delete
          </GButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
