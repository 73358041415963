import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { AppStoreContext } from '../common/AppStore';

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
  },
  example: {
    fontSize: 11,
    color: 'gray',
    margin: theme.spacing(1),
  },
  conversion: {
    color: 'black',
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
  },
}));


export default function SimpleTable() {
  const classes = useStyles();

  const { activityTypes } = useContext(AppStoreContext);

  return (
    <>
      <Typography variant="h6" className={classes.header}>Activity point table</Typography>
      {activityTypes.map(({
        activityTitle, unit, 'convert-rate': conversion, example,
      }) => (
        <div key={activityTitle}>
          <Typography variant="body1" className={classes.title}>{activityTitle}</Typography>
          <div className={classes.example}>
            <Typography variant="body2" className={classes.conversion}>{`10 ${unit} = ${conversion * 10} points`}</Typography>
            {(example || []).length > 0 && 'Examples'}
            <ul>
              {(example || []).map((ex) => (
                <li key={ex}>{ex}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
}
