import React, { useContext, useState, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useMediaQuery } from '@material-ui/core';

import { AppStoreContext } from '../common/AppStore';
import ActivityForm from './ActivityForm';

import GButton from './GButton';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap', // Ensure items wrap to the next line if needed
  },
  listItemText: {
    flexGrow: 1,
  },
  subTitle: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    color: 'grey',
  },
  help: {
    fontSize: 14,
    paddingLeft: theme.spacing(1),
  },
  secondary: {
    display: 'flex',
    alignItems: 'center',
  },
  popoverContent: {
    padding: '20px',
    maxWidth: '300px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px', // Adjust top position as needed
    right: '10px', // Adjust right position as needed
    cursor: 'pointer',
  },
  registerButton: {
    flexShrink: 1, // Ensure button doesn't shrink on smaller screens
  },
}));

export default function MyInfo() {
  const classes = useStyles();
  const { profile, leaderboard } = useContext(AppStoreContext);
  const isExtraExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xxs'));

  const [open, setOpen] = useState(false);
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const registerActivityButtonRef = useRef(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handleClickOpen = () => {
    setPopoverAnchorEl(registerActivityButtonRef.current);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Get user total points from leaderboard
  const leaderboardIndex = (leaderboard || []).findIndex(entry => profile.userId === entry.userId);
  const totalPoints = leaderboardIndex !== -1 ? leaderboard[leaderboardIndex].totalPoints.toFixed(0) : '-';
  const position = leaderboardIndex !== -1 ? leaderboardIndex + 1 : '-';

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        My activity
      </Typography>

      <List>
        <ListItem className={classes.listItem}>
          <ListItemAvatar>
            <Avatar alt={profile.name} src={profile.avatar} />
          </ListItemAvatar>
          <ListItemText
            primary={profile.name}
            secondary={`${totalPoints || 0} points (position: ${position})`}
            className={classes.listItemText}
          />
          <div style={{ flexGrow: 1 }}></div> {/* Add this empty div to push the button to the next line on smaller screens */}
          <div className={classes.registerButton}>
            <GButton
              size={isExtraExtraSmallScreen ? "small" : "medium"}
              color="primary"
              variant="Main"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
              ref={registerActivityButtonRef}
            >
              Register Activity
            </GButton>
          </div>
        </ListItem>
      </List>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth>
        <ActivityForm onOpenPopover={() => setConfirmationPopUp(true)} onAdd={handleClose} onCancel={handleClose} />
      </Dialog>
      <Popover
        open={confirmationPopUp}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className={classes.popoverContent}>
          <CloseIcon className={classes.closeButton} onClick={() => setConfirmationPopUp(false)} />
          <Typography variant="h6">Well done Ninja</Typography>
          <Typography>
            Keep up the good work! Remember to stay active and healthy. You're doing great! 👍
          </Typography>
          <img src="/greatjob.png" alt="Icon" style={{ width: '100%', marginTop: '20px' }} />
        </div>
      </Popover>
    </div>
  );
}
