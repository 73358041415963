import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { AppStoreContext } from '../common/AppStore';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(2),
    marginBottom: 0,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  subTitle: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    color: 'grey',
  },
  skel: {
    padding: theme.spacing(3),
  },
}));

export default function ScoresContainer({ title, children, maxHeight, week }) {
  const classes = useStyles();
  const {
    networkLoading,
  } = useContext(AppStoreContext);

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="caption" className={classes.subTitle}>
        {`Week ${week} `}
      </Typography>
      <div style={{ overflow: 'auto', maxHeight }}>
        {!networkLoading.boot && !networkLoading['get-competition-scores'] ? (
          children
        ) : (
          <div className={classes.skel}>
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </div>
        )}
      </div>
    </div>
  );
}
