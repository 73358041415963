export const palette = {
    primary: {
        main: "rgb(22, 22, 50)",
        contrasText: 'rgb(255, 255, 255)',
    },
    secondary: {
        main: 'rgb(244, 0, 19)',
        contrasText: 'rgb(255, 255, 255)',
    },
    accent: {
        main: 'rgb(160, 234, 255)',
    }
}

export const typography = {
    fontFamily: [
        'Rubik',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Oxygen-Sans',
        'Ubuntu',
        'Cantarell',
        "Helvetica Neue",
        'sans-serif'
    ].join(','),
}