/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Container,
  AppBar,
  Toolbar,
  Typography,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ToastContainer } from 'react-toastify';
import { palette, typography } from './common/theme';

import Main from './pages/Main';
import Login from './pages/Login';

import { AppStoreContext, useAppStore } from './common/AppStore';
import { LOGIN_LINK } from './common/config';

import 'react-toastify/dist/ReactToastify.css';

const theme = createMuiTheme({
  palette,
  typography,
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    flex: '0 0 60px',
    width: '58px',
    height: '48px',
    backgroundImage: 'url(/nordcloud.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    float: 'left',
    marginRight: '0rem',
    // Adjust logo size for smaller screens
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '32px',
    },
  },
  subTitle: {
    flex: '1 1 auto',
    textAlign: 'center',
    // Adjust font size for smaller screens
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },  
  background: {
    backgroundImage: 'url(/background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center left', // Align background image to center left
    backgroundSize: '50%', // Adjust background size here
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    zIndex: -1,
  },
  appBar: {
    height: 76,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginLeft: '-12px',
    marginRight: '1rem',
    justifyContent: 'center',
    alignItems: 'center',

  },
  title: {
    paddingLeft: 4,
    width: 128,
    textAlign: 'center',
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
  },
  profileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function App() {
  const classes = useStyles();
  const appStore = useAppStore();

  const { profile } = appStore;

  useEffect(() => {
    const url = window.location.href;
    const match = url.match(/user=([^#]+)/);

    if (match) {
      const [, user_string] = match;
      if (user_string) {
        const profileJson = JSON.parse(decodeURI(user_string));
        appStore.setProfile(profileJson);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <AppStoreContext.Provider value={appStore}>
          <div className={classes.background} />
          <div className={classes.root}>
            <AppBar position="fixed" color="primary">
              <Container maxWidth="md">
                <Toolbar className={classes.appBar}>
                  <div className={classes.titleContainer}>
                    <div className={classes.logo} />
                    <Typography variant="h4" className={classes.subTitle}>
                      Activity challenge
                    </Typography>
                  </div>
                  { profile && profile.name ? (
                    <Button color="inherit" onClick={() => { appStore.logout(); }}>Logout</Button>
                  ) : (
                    <Button color="inherit" href={LOGIN_LINK}>Login</Button>
                  )}
                </Toolbar>
              </Container>
            </AppBar>
            <Container maxWidth="md" className={classes.container}>
              { profile && profile.name ? (
                <Main />
              ) : (
                <Login />
              )}
            </Container>
          </div>
          <ToastContainer />
        </AppStoreContext.Provider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
