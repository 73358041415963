import axios from 'axios';

import {
  BACKEND_API,
} from './config';

const API = axios.create({
  baseURL: BACKEND_API,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

async function doGet(path, params = {}) {
  const { data, status } = await API.get(path, { params });

  if (status !== 200) {
    throw new Error(`(${status}) ${data.code} - ${data.message}`);
  }

  return data;
}

async function doDelete(path, params = {}) {
  const { data, status } = await API.delete(path, params);

  if (status !== 200) {
    throw new Error(`(${status}) ${data.code} - ${data.message}`);
  }

  return data;
}

async function doPost(path, payload) {
  const { data, status } = await API.post(path, payload);

  if (status !== 200) {
    throw new Error(`(${status}) ${data.code} - ${data.message}`);
  }

  return data;
}

export function updateToken(token) {
  API.defaults.headers.common.Authorization = token;
}

export async function addActivity(activity) {
  return doPost(`/activity`, {
    ...activity,
  });
}

export async function removeActivity(activityId) {
  return doDelete(`/activity/${activityId}`);
}


export async function getActivityList() {
  return doGet('/activities')
}

export async function getUserDetails({ userId, startTime, endTime }) {
  return doGet(`/user/${userId}`, { startTime, endTime })
}

export async function getTop10() {
  const weeksBack = 15;
  return doGet(`/top10/${weeksBack}`);
}


export async function getCompetitionScores() {
  return doGet('/competition-scores');
}

export async function getLeaderboard() {
  return doGet('/leaderboard');
}

export async function getLeaderboards() {
  return doGet('/leaderboards');
}

export async function getUsers() {
  return doGet('/users');
}

export async function getProfile() {
  return doGet('/profile');
}

export async function boot() {
  return doGet('/boot');
}
