import React from 'react';
import {
  Paper,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LOGIN_LINK } from '../common/config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(50vh - 76px)',
  },
  paper: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: 260,
    width: 380,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Login() {
  const classes = useStyles();
  return (
    <div className={classes.root}>      
      <Paper className={classes.paper}>
        <Button variant="contained" color="primary" href={LOGIN_LINK}>Login</Button>
      </Paper>
    </div>
  );
}
