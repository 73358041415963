import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Avatar from '@material-ui/core/Avatar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AppStoreContext } from '../common/AppStore';
import LeaderChart from './LeaderChart.jsx';

const CHART_HEIGHT = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  chart: {
    width: '100%',
    height: CHART_HEIGHT,
  },
  list: {
    overflow: 'auto',
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  table: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  person: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    flex: '0 0 40px',
    alignSelf: 'center',
    marginRight: '1rem'
  },
  name: {
    flex: '1 1 auto',
    alignSelf: 'center',
  },
  dateCell: {
    fontSize: 12,
  },
  checkCell: {
    verticalAlign: 'top',
    paddingRight: 0,
  },
  valueCell: {
    fontSize: 12,
    verticalAlign: 'top',
  },
  iconContainer: {
    display: 'contents',
  },
  title: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  alert: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  skel: {
    padding: theme.spacing(3),
  },
  help: {
    float: 'right',
    color: 'silver',
    cursor: 'pointer',
  },
  subTitle: {
    display: 'block',
    color: 'grey',
  },  
}));

function LeaderboardList() {
  const classes = useStyles();
  const { getLeaderboard, leaderboard, users, userDetails, getUserDetails, activityTypes, leaderboards } = useContext(AppStoreContext);
  const [leaderboardUsers, setLeaderboardUsers] = useState([])
  const [userDetailsId, setUserDetailsId] = useState(null);

  // Update leaderboard every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getLeaderboard()
    }, 30000);
    return () => clearInterval(interval);
  }, [getLeaderboard]);

  // Get user details on request
  useEffect(() => {
    if (!userDetailsId) {
      return;
    }
    getUserDetails(userDetailsId)
  }, [userDetailsId]);


  // Create leaderboard with user info
  useEffect(() => {
    setLeaderboardUsers(
      leaderboard
        .filter(entry => entry.totalPoints !== 0) // Filter out users with zero points
        .map(entry => {
          const user = users.find(user => user.userId === entry.userId)
          return {
            userId: entry.userId,
            name: user?.name || 'Anonymous',
            avatar: user?.avatar || '', 
            totalPoints: entry.totalPoints,
          }
        })
        .slice(0, 20) // Limit the list to top 10
    );
  }, [leaderboard, users]);

  return (
    (leaderboardUsers && leaderboardUsers.length) ? (
      <>
        <div className={classes.chart}>
          <LeaderChart height={CHART_HEIGHT} users={leaderboardUsers} leaderboards={leaderboards} />
        </div>
        <TableContainer className={classes.list}>
          <Table stickyHeader className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Employee</TableCell>
                <TableCell align="right">Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboardUsers.map((entry, i) => {
                return (
                  <TableRow key={`leaderboard-${i}`}>
                    <TableCell className={classes.checkCell}>
                      <IconButton aria-label="expand row" size="small" onClick={() => setUserDetailsId(userDetailsId === entry.userId ? null : entry.userId)}>
                        {userDetailsId === entry.userId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>                  
                    <TableCell component="th" scope="row">
                      <div className={classes.person}>
                        <Avatar alt={entry.name} src={entry.avatar} className={classes.avatar} />
                        <div className={classes.name}>{entry.name}</div>
                      </div>
                      <Collapse in={userDetailsId === entry.userId} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>Activity</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Points</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userDetails?.activities?.map((activity, rowId) => {
                                const activityEntry = activityTypes.find(activityType => activityType.activityTypeId === activity.activityTypeId) 
                                return (
                                  <TableRow key={rowId}>
                                    <TableCell>{activityEntry ? activityEntry.activityTitle : activity.activityTypeId} ({moment(activity.timestamp).fromNow()})</TableCell>
                                    <TableCell>{activity.value} {activity.unit}</TableCell>
                                    <TableCell>{activity.points.toFixed(0)}</TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                    <TableCell align="right" className={classes.valueCell}>{entry.totalPoints.toFixed(0)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      <Alert className={classes.alert} severity="info">No leaderboard yet</Alert>
    )
  );
}

export default function Leaderboard() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Leaderboard 🏆
        <Typography variant="caption" className={classes.subTitle}>
          Total points collected
        </Typography>
      </Typography>
      <LeaderboardList />
    </div> 
  )
}
