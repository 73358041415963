import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Score from '@material-ui/icons/Score';
import AccessTime from '@material-ui/icons/AccessTime';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import { useForm } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import GButton from './GButton';
import { AppStoreContext } from '../common/AppStore';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import '../App.css';
import MyPopover from './MyPopover';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  inputRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  inputIcon: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  spinner: {
    marginLeft: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

function IconOnSide({ icon, children }) {
  const classes = useStyles();
  return (
    <div className={classes.inputRoot}>
      {icon}
      {children}
    </div>
  );
}

export default function ActivityForm({ onOpenPopover, onAdd, onCancel }) {
  const classes = useStyles();

  const emptyActivity = { activityTitle: null, unit: null };
  const [activity, setActivity] = useState(emptyActivity);
  const [checkValue, setCheckValue] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    register, errors, handleSubmit,
  } = useForm();
  const { addActivity, networkLoading, activityTypes } = useContext(AppStoreContext);

  const [openDate, setOpenDate] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      const lastUsedActivity = JSON.parse(localStorage.getItem('lastUsedActivity')) || {};
      if (lastUsedActivity.activityTypeId) {
        setActivity(lastUsedActivity);
      }
    }, 200);

    return (() => {
      clearTimeout(time);
    });
  }, []);

  const handleCheck = (e) => {
    setCheckValue(e.target.checked);
  };
  const onSubmit = (
    { value },
  ) => {
    const { activityTitle, unit, activityTypeId } = activity;
    value = checkValue ? value * 2 : value;
    addActivity({
      datetime: selectedDate.toISOString(),
      value,
      activityTypeId,
      activityTitle,
      unit,
    });
    onAdd();
    onOpenPopover();
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleAddButtonPopoverOpen = (event) => {
    onOpenPopover();
  };

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" className={classes.title}>
        Add Activity
      </Typography>

      <div className={classes.inputRoot}>
        <Autocomplete
          options={activityTypes}
          getOptionSelected={(option) => option.activityTypeId === activity.activityTypeId}
          getOptionLabel={(option) => (option.activityTitle ? `${option.activityTitle} (${option.unit})` : '')}
          onChange={(ev, value) => {
            if (value) {
              setActivity(value);
              localStorage.setItem('lastUsedActivity', JSON.stringify(value));
            } else {
              setActivity(emptyActivity);
            }
          }}
          defaultValue={emptyActivity.activityTypeId}
          value={activity}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Activity type"
              name="activity"
              inputRef={register({ required: true })}
              helperText={errors.activity ? 'Required' : null}
              error={!!errors.activity}
              fullWidth
            />
          )}
        />
        <div className={classes.checkboxContainer}>
          <Checkbox color="primary" checked={checkValue} onChange={handleCheck} />
          <Typography style={{ fontWeight: 'bold' }}>I did this with a colleague</Typography>
          <InfoOutlinedIcon onClick={handlePopoverOpen} style={{ cursor: 'pointer' }} />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Typography style={{ padding: '10px' }}>
              <span style={{ fontWeight: 'bold' }}>Double points ⭐️🎉</span>
              <div>Disclaimer <img src="/disclaimer.png" alt="Icon" style={{ marginRight: '5px', width: '20px', height: '20px' }} />: Photo in Slack or it didn’t happen!</div>
            </Typography>
          </Popover>
        </div>
      </div>

      <IconOnSide icon={<Score />}>
        <div className={classes.inputContainer}>
          <TextField
            defaultValue=""
            name="value"
            type="number"
            label="Value"
            inputRef={register({ required: true })}
            helperText={errors.value ? 'Required' : null}
            error={!!errors.value}
            fullWidth
          />
          <SquareFootIcon className={classes.inputIcon} />
          <Typography>{activity.unit}</Typography>
        </div>
      </IconOnSide>

      <IconOnSide icon={<AccessTime />}>
        <div className={classes.inputContainer}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            inputRef={register({ required: true })}
            name="datetime"
            label="Date of activity"
            format="YYYY-MM-DD"
            value={selectedDate}
            onChange={handleDateChange}
            helperText={errors.datetime ? 'Required' : null}
            error={!!errors.datetime}
            onClick={() => { setOpenDate(true); }}
            onOpen={() => setOpenDate(true)}
            onClose={() => setOpenDate(false)}
            open={openDate}
            fullWidth
          />
        </div>
      </IconOnSide>

      <div style={{ textAlign: 'center', marginTop: '16px' }} >
        {networkLoading['add-activity'] && (<CircularProgress size={16} className={classes.spinner} />)}
        <div className="buttons">
          <GButton type="submit" variant="Add" disabled={networkLoading['add-activity']}>
            Add
          </GButton>
          <GButton variant="Cancel" onClick={onCancel}>Cancel</GButton>
        </div>
      </div>
    </form>
  );
}
