import React, { useReducer, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as client from './client';
import { START_TIME, END_TIME } from './config'



const AppStoreContext = React.createContext();

// Custome hook
function useAppStore() {
  const initStore = {
    profile: {},
    userDetails: {},
    users: [],
    top10Scores: [],
    top10ScoresPrevWeek: [],
    leaderboard: [],
    leaderboards: [],
    activities: [],
    activityTypes: [],
    networkLoading: {},
    subScores: [],
  };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'setUsers': {
        const { users } = payload;
        return { ...state, users };
      }
      case 'setScore': {
        const { totalScore } = payload;
        return { ...state, profile: { ...state.profile, totalScore } };
      }
      case 'setUserDetails': {
        const { userDetails } = payload;
        return { ...state, userDetails };
      }
      case 'setProfile': {
        const { profile } = payload;
        return { ...state, profile };
      }
      case 'updateNetwork': {
        const { routeName, active } = payload;
        return {
          ...state,
          networkLoading: {
            ...state.networkLoading,
            [routeName]: active,
          },
        };
      }
      case 'setSubScores': {
        const { subScores } = payload;
        return { ...state, subScores };
      }
      case 'setTop10': {
        const { top10Scores } = payload;
        return { ...state, top10Scores: top10Scores || [] };
      }
      case 'setTop10ScoresPrevWeek': {
        const { top10ScoresPrevWeek } = payload;
        return { ...state, top10ScoresPrevWeek: top10ScoresPrevWeek || [] };
      }
      case 'setActivities': {
        const { activities } = payload;
        return { ...state, activities };
      }
      case 'setLeaderboard': {
        let { leaderboard } = payload;
        return { ...state, leaderboard };
      }
      case 'setLeaderboards': {
        let { leaderboards } = payload;
        return { ...state, leaderboards };
      }
      case 'setActivityTypes': {
        const { activityTypes } = payload;
        return { ...state, activityTypes };
      }
      case 'reset': return initStore;
      default: throw new Error('Unexpected action');
    }
  };

  const [store, dispatch] = useReducer(reducer, initStore);

  function logout() {
    localStorage.removeItem('lastUsedActivity');
    localStorage.removeItem('profile');
    dispatch({ type: 'reset' });
    // window.location.reload();
  }

  async function requestApi(routeName, data, apiCall, callBack) {
    const routeTimer = setTimeout(() => {
      dispatch({ type: 'updateNetwork', payload: { routeName, active: true } });
    }, 200);

    try {
      const result = await apiCall(data);
      callBack(result);
    } catch (e) {
      const { status } = e.response || {};
      if (status === 401) {
        toast.warn('Session ended, please login again.');
      } else {
        toast.error(`API fail, ${e.message}`);
      }
      logout();
    } finally {
      clearTimeout(routeTimer);
      dispatch({ type: 'updateNetwork', payload: { routeName, active: false } });
    }
  }

  function updateLocalProfile(profile) {
    localStorage.setItem('profile', JSON.stringify(profile));
    client.updateToken(profile.token);
    dispatch({ type: 'setProfile', payload: { profile } });
  }

  function getUserDetails(userId) {
    requestApi('user-details', { userId, startTime: START_TIME.valueOf(), endTime: END_TIME.valueOf() }, client.getUserDetails, (userDetails) => {
      dispatch({ type: 'setUserDetails', payload: { userDetails } });
    });

  }

  function boot() {
    requestApi('boot', null, client.boot, ({
      users, activities, leaderboard, activityTypes, top10Scores,
      top10ScoresPrevWeek, totalScore, subScores, leaderboards
    }) => {
      dispatch({ type: 'setUsers', payload: { users } });
      dispatch({ type: 'setActivities', payload: { activities: activities.filter(activity => activity.timestamp >= START_TIME.valueOf() && activity.timestamp <= END_TIME.valueOf()) } });
      dispatch({ type: 'setActivityTypes', payload: { activityTypes } });
      dispatch({ type: 'setTop10', payload: { top10Scores } });
      dispatch({ type: 'setTop10ScoresPrevWeek', payload: { top10ScoresPrevWeek } });
      dispatch({ type: 'setScore', payload: { totalScore } });
      dispatch({ type: 'setSubScores', payload: { subScores } });
      dispatch({ type: 'setLeaderboard', payload: { leaderboard } });
      dispatch({ type: 'setLeaderboards', payload: { leaderboards } });
    });
  }

  useEffect(() => {
    const retrievedProfile = localStorage.getItem('profile');
    const localProfile = JSON.parse(retrievedProfile);
    if (localProfile) {
      updateLocalProfile(localProfile);
      boot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setProfile(profile) {
    updateLocalProfile(profile);
    window.history.pushState({}, document.title, '/');
    boot();
  }

  function getLeaderboard() {
    requestApi('leaderboard', null, client.getLeaderboard, (leaderboard) => {
      dispatch({ type: 'setLeaderboard', payload: { leaderboard } });
    });
  }

  function getCompetitionScores() {
    requestApi('get-competition-scores', null, client.getCompetitionScores, ({ top10Scores, top10ScoresPrevWeek, subScores }) => {
      dispatch({ type: 'setTop10', payload: { top10Scores } });
      dispatch({ type: 'setTop10ScoresPrevWeek', payload: { top10ScoresPrevWeek } });
      dispatch({ type: 'setSubScores', payload: { subScores } });
    });
  }

  let refreshTimeout;
  function refresh() {
    clearTimeout(refreshTimeout);
    refreshTimeout = setTimeout(() => {
      getLeaderboard();
      getCompetitionScores();
    }, 300);
  }

  function addActivity({
    datetime,
    value,
    // activityTitle,
    activityTypeId,
    unit,
  }) {
    const activity = {
      datetime: new Date(datetime).toISOString(),
      value: Number(value),
      // activityTitle,
      activityTypeId,
      unit,
    };
    requestApi('add-activity', activity, client.addActivity, ({ activityId }) => {
      const { activityTypes, activities, profile: { totalScore } } = store;
      const { 'convert-rate': convertion } = activityTypes.filter((i) => i.activityTypeId === activityTypeId)[0] || {};

      const newList = [...activities];
      newList.unshift({ activityId, ...activity });
      dispatch({ type: 'setActivities', payload: { activities: newList } });
      if (moment(activity.datetime).week() === moment().week()) {
        dispatch({ type: 'setScore', payload: { totalScore: ((totalScore || 0) + Math.round(convertion * value)) } });
      }
      refresh();
    });
  }

  function deleteActivity(index) {
    const { activityTypes, activities, profile: { totalScore } } = store;
    const { activityId, activityTypeId, value } = activities[index];
    const { 'convert-rate': convertion } = activityTypes.filter((i) => i.activityTypeId === activityTypeId)[0] || {};

    requestApi('delete-activity', activityId, client.removeActivity, () => {
      if (index > -1) {
        const activityToDelete = activities[index];
        activities.splice(index, 1);

        const newList = [...activities];
        dispatch({ type: 'setActivities', payload: { activities: newList } });
        if (moment(activityToDelete.datetime).week() === moment().week()) {
          dispatch({ type: 'setScore', payload: { totalScore: ((totalScore || 0) - Math.round(convertion * value)) } });
        }
        refresh();
      }
    });
  }

  function getActivityList() {
    requestApi('get-activity-list', null, client.getActivityList, (activities) => {
      console.log(
        'act', activities
      );
      dispatch({ type: 'setActivities', payload: { activities } });
    });
  }

  return {
    boot,
    getCompetitionScores,
    logout,
    getUserDetails,
    userDetails: store.userDetails,
    setProfile,
    users: store.users,
    getLeaderboard,
    leaderboard: store.leaderboard,
    leaderboards: store.leaderboards,
    addActivity,
    deleteActivity,
    activityList: store.activities,
    activityTypes: store.activityTypes,
    profile: store.profile,
    networkLoading: store.networkLoading,
    top10Scores: store.top10Scores,
    top10ScoresPrevWeek: store.top10ScoresPrevWeek,
    subScores: store.subScores,
    getActivityList,
  };
}


export {
  AppStoreContext,
  useAppStore,
};
