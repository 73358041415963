import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import moment from 'moment';

import Scores from './ScoresContainer';

import { AppStoreContext } from '../common/AppStore';

export default function Top10LastWeek({}) {
  const week = moment().subtract(1, 'weeks').format('w');
  const {
    top10ScoresPrevWeek
  } = useContext(AppStoreContext);

  // Slice the top 5 scores
  const top5Scores = top10ScoresPrevWeek.slice(0, 5);

  return (
    <Scores title="Most points last week 👟" maxHeight={400} week={week}>
      <List>      
        {top5Scores.map(({ name, avatar, totalScore }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={`score-${i}`}>
            <ListItemAvatar>
              <Avatar alt={name} src={avatar} />
            </ListItemAvatar>
            <ListItemText primary={`${i + 1} - ${name}`} secondary={`${totalScore} points`} />
          </ListItem>
        ))}
      </List>
    </Scores>
  );
}