import React from 'react';
import {
  Grid,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LastActivities from '../components/LastActivities';
import MyScore from '../components/MyInfo';
import Leaderboard from '../components/Leaderboard';
import Top10 from '../components/Top10';
import Top10LastWeek from '../components/Top10lastWeek';
import SubScores from '../components/SubScores';
import MotivationBox from '../components/MotivationContainer';
//import HallofFame from '../components/HallofFame';

import BottomBar from '../components/BottomBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 140,
    minWidth: 100,
  },
}));


export default function Main() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={8}>
        <Paper className={classes.paper}>
          <MyScore />
          <LastActivities />
          { <Leaderboard /> }
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Top10 week={2} />
          <Top10LastWeek week={1} />
        </Paper>
        <Paper className={classes.paper}>
          <SubScores />
        </Paper>
        <Paper className={classes.paper}>
          <MotivationBox />
        </Paper>
        <Paper className={classes.paper}>
          <BottomBar />
        </Paper>
      </Grid>
    </Grid>
  );
}
