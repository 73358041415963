import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  bottomBar: {
    backgroundColor: '#ffffff',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[3],
    zIndex: theme.zIndex.appBar - 1,
    transition: 'transform 0.3s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  heading: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1), // Add margin bottom for spacing
  },
  creditList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap', // Ensure items wrap to the next line if needed
  },
  creditItem: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
    textAlign: 'center', // Center align items
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    border: `2px solid ${theme.palette.primary.main}`,
  },
  name: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: '0.9rem',
  },
  role: {
    color: theme.palette.text.secondary,
    fontSize: '0.8rem',
  },
  hidden: {
    transform: 'translateY(100%)',
  },
}));

const credits = [
  { name: 'Joni Kämppä', imageUrl: 'joni.png', role: 'Developer' },
  { name: 'Juha Mustonen', imageUrl: 'juha.png', role: 'Developer' },
  { name: 'Samer Mlattialy', imageUrl: 'samer.png', role: 'Developer' },
  { name: 'Masood Rafay', imageUrl: 'masood.jpg', role: 'Developer' },
];

const BottomBar = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bottomThreshold = window.innerHeight * 0.1;
      const isScrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - bottomThreshold;
      setIsVisible(isScrolledToBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${classes.bottomBar} ${isVisible ? '' : classes.hidden}`}>
      <Typography variant="h6" className={classes.heading}>Hall of Fame</Typography> {/* Add heading */}
      <div className={classes.creditList}>
        {credits.map((credit, index) => (
          <div key={index} className={classes.creditItem}>
            <Avatar alt={credit.name} src={credit.imageUrl} className={classes.avatar} />
            <div>
              <Typography variant="body1" className={classes.name}>
                <Hidden smDown>{credit.name}</Hidden>
              </Typography>
              <Typography variant="body2" className={classes.role}>
                {credit.role}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomBar;
