import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(2),
    marginBottom: 0,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  subTitle: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    color: 'grey',
  },
  skel: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default function MotivationBox() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Inspirational tips  
      </Typography>
      <Typography variant="caption" className={classes.subTitle}>
        Motivation for those extra points
      </Typography>
      <div className={classes.skel}>
        <List>
        <ListItem>
            <ListItemText
                primary="Walking 1:1 meetings"
                secondary="Ask your manager if you can take that next 1:1 meeting walking"
                />
        </ListItem>
        <ListItem button component="a" href="https://nordcloud.atlassian.net/wiki/spaces/ED/pages/4387996566/Sweden+benefits#Benify---Benefit-portal">
            <ListItemText
                primary="Friskvårdsbidrag/Wellness grant"
                secondary="Wellness grant can be used for many types of wellness, example to buy a gym card or play golf with your best friend. More info in portal."
                />
        </ListItem>
        <ListItem button component="a" href="https://turfgame.com/info">
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText
              primary="Turfgame"
              secondary="Geo location game where you captures virtual zones"
            />
          </ListItem>
          <ListItem button component="a" href="https://www.geocaching.com/play">
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText
              primary="Geocaching"
              secondary="Find hidden geocaches" />
          </ListItem>
          
        </List>
      </div>
   </div>
  );
}
