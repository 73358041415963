import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const SHOW_TOP = 20;

export default function LeaderChart({ height, users, leaderboards }) {
    // Construct the data from leaderboards
    const data = [];

    if (leaderboards.length < 1 || leaderboards == undefined) {
      return(
        <div>No leaderboard yet</div>
      );
    }

    // Get top users (both leaderboard and entries are already ordered)
    const topXUsers = (leaderboards[leaderboards.length - 1]).entries.slice(0, SHOW_TOP);

    // Iterate users
    (topXUsers || []).map(user => {
      const userEntries = [];
      // Iterate all leaderboards and pick user entries
      (leaderboards || []).forEach(leaderboard => {
        const userEntry = leaderboard.entries.find(entry => entry.userId === user.userId);
        if (userEntry) {
          userEntries.push({
            x: new Date(leaderboard.timestamp),
            y: userEntry.totalPoints,
          });
        }
      })

      if (userEntries.length > 0) {
        // Put user icon for last entry
        const userInfo = users.find(userInfo => userInfo.userId === user.userId)
        if (userInfo) {
          userEntries[userEntries.length - 1].marker = {
            symbol: `url(${userInfo.avatar})`,
            height: 30,
            width: 30,
          }
        }
        data.push({ name: user.userId, data: userEntries });
      }
    })

    const options = {
      chart: {
        height,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
          formatter: function () {
              const user = users.find(user => user.userId === this.series.name)
              return `<b>${user?.name || 'Anonymous'}</b>: ${this.y.toFixed(0)} points`;
          }
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: 'Points'
        },
        min: 0
      },      
      series: data
    }

    return (<HighchartsReact
      highcharts={Highcharts}
      options={options}
    />)
}