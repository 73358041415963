import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

function MyPopover({ open, anchorEl, onClose }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div style={{ padding: '20px', maxWidth: '300px' }}>
        <Typography variant="h6">Well done Ninja</Typography>
        <Typography>
          Keep up the good work! Remember to stay active and healthy. You're doing great! 👍
        </Typography>
        {/* Add your image or any other content here */}
      </div>
    </Popover>
  );
}

export default MyPopover;
