import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  button: {
    padding: '11px 36px',
    borderRadius: '30px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '700',
    minWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '150px',
      padding: '8px 20px', // Adjust padding as needed
      fontSize: '12px', // Adjust font size as needed
    },
  },
  Add: {
    backgroundColor: 'rgb(0, 252, 223)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '2px solid',
      color: 'rgb(0, 252, 223)',
      borderColor: 'rgb(0, 252, 223)',
    },
  },
  Cancel: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '2px solid',
      color: 'rgb(22, 22, 50)',
      borderColor: 'rgb(22, 22, 50)',
    },
  },
  Main: {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '2px solid',
      color: 'red',
      borderColor: 'red',
    },
  },
}));

const GButton = ({ children, onClick, className, variant, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      className={`${classes.button} ${classes[variant]} ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default GButton;
