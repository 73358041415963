import moment from 'moment';

// ISO-8601, Europe
moment.updateLocale("en", {
  week: {
    dow: 1, // First day of week is Monday
    doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
  }
});

export const BACKEND_API = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8080' : 'https://step-up-competition.appspot.com';

export const LOGIN_LINK = `${BACKEND_API}/auth/google`;
export const START_TIME = moment().startOf('month').toDate()
export const END_TIME = moment().endOf('month').toDate()