import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import moment from 'moment';

import Scores from './ScoresContainer';

import { AppStoreContext } from '../common/AppStore';

export default function Top10() {
  const week = moment().format('w');
  const {
    subScores,
  } = useContext(AppStoreContext);
  return (
    <Scores title="Activities registered 👟"  week={week}>
      <List>
        {Object.values(subScores).map(({
          name, avatar, score, activityTitle, unit,
        }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={`score-${i}`}>
            <ListItemAvatar>
              <Avatar alt={name} src={avatar} />
            </ListItemAvatar>
            <ListItemText primary={name} secondary={`${activityTitle} with ${score} ${unit}`} />
          </ListItem>
        ))}
      </List>
    </Scores>
  );
}